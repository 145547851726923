import { AuthenticationContext } from "@moneta/core/authentication";
import { Link } from "@remix-run/react";
import { Effect, Option } from "effect";
import { defineLoaderEffect, ServerRedirectResult } from "~/.server";
import { Button, LoginLayout } from "~/components";

export const loader = defineLoaderEffect(() =>
  Effect.gen(function* () {
    const authContext = yield* AuthenticationContext;
    const userId = yield* authContext.currentUserId();
    if (Option.isSome(userId)) {
      return yield* ServerRedirectResult.throw({ url: "/", status: 303 });
    }

    return { data: null };
  }),
);

export default function Login() {
  return (
    <LoginLayout>
      <div className="flex flex-col gap-8 px-2 py-6 md:py-16">
        <h2 className="text-center text-2xl font-normal tracking-tighter text-black">
          Use your Google account
          <br />
          to start using Moneta.
        </h2>
        <Button size="large" asChild>
          <Link to="/auth/google/login" reloadDocument>
            Sign in with Google
          </Link>
        </Button>
      </div>
    </LoginLayout>
  );
}
